// @flow
import React, { useState, useContext, useEffect } from 'react';
import type {
  ChronologyType,
  EvaluatedCorporateEmployee,
  MembershipOfferFrontendModel,
  MembershipType,
} from 'app/ui/types';
import {
  cancelEditingMembership,
  cancelNewMembership,
  membershipSaveSuccess,
  newMembershipSaveSuccess,
  openEditChildMembershipModal,
  savingMembership,
} from 'app/ui/user-manager/user/memberships/actions';
import TextFormField from 'app/ui/components/TextFormField';
import DropdownFormField from 'app/ui/components/DropdownFormField';
import { Form } from 'react-final-form';
import {
  formatDateToISODate,
  formatISOZonedDateTimeToISODate,
  formatISOZonedDateTimeToLocalDate,
} from 'app/utils/format/dateTimeFormatter';
import { httpGet, httpPatch, httpPost } from 'app/service/http';
import { membershipCancellationDropdownOptions } from 'app/ui/user-manager/user/memberships/membershipUtils';
import {
  validateMembership,
  isMembershipStartTooFarInPast,
  isDateTooFarInTime,
  validateDates,
} from 'app/ui/user-manager/user/memberships/validator';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import UserMembershipsContext from 'app/ui/user-manager/user/memberships/context/UserMembershipsContext';
import { FORM_ERROR } from 'final-form';
import SubmitErrors from 'app/ui/components/SubmitErrors';
import moment from 'moment';
import { convertGivenLocalDateToUtc } from '../../../../utils/date/dateUtil';
import { VALIDATION_B2C_CHARGE_REQUEST_EXISTS } from '../../../../validation/common/errorCodes';
import { simpleMemoize } from '../../../../service/http/requestUtil';
import { isSame } from '../../../../utils/object/objectUtil';
import { DateWarningModal } from './MembershipWarningModal';
import UserContext from '../context/UserContext';
import { getCountryFlag } from '../../../../utils/country/flagGenerator';
import { formatAddress } from '../../../../utils/format/addressFormater';

type Props = {
  membership: MembershipType,
  membershipOfferDto: MembershipOfferFrontendModel,
  chronology: ChronologyType,
};

/**
 * This component is used for both Editing Existing Membership or creating new Membership for the user.
 *
 * To tell the mode of operation, we can use the membership.uuid property:
 * 1) When there is NO membership.uuid, then the component is used to create new Membership
 * 2) When there is a membership.uuid, then the component is used to edit the Membership with that ID.
 */
const EditMembership = ({
  membership,
  membershipOfferDto,
  relatedSignupPageNames,
  referrerMembership,
  referralMembership,
  chronology,
}: Props) => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const { state, dispatch } = useContext(UserMembershipsContext);
  const { user } = React.useContext(UserContext);
  const [membershipDatesToConfirm, setMembershipDatesToConfirm] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalBody, setModalBody] = useState(null);
  const [startDateLabel, setStartDateLabel] = useState(null);
  const [endDateLabel, setEndDateLabel] = useState(null);
  const [currentMembershipValues, setCurrentMembershipValues] = useState(null);
  const [warning, setWarning] = useState(null);
  const [isInvalidUserAddress, setIsInvalidUserAddress] = useState(false);
  const [evaluatedCorporateEmployee: EvaluatedCorporateEmployee, setEvaluatedCorporateEmployee] =
    useState(null);

  const isEditMode = () => !!membership.uuid;
  const pastMembershipEndDateStillEditable = () =>
    moment().diff(moment(membership.membershipEndTimestamp), 'days') <= 4;

  /** Saves changes for existing Membership */
  const submitPatchRequest = values => {
    dispatch(savingMembership());
    const patchMembership = async () => {
      try {
        let membershipWrapper;

        if (
          !isSame(
            values.membership.membershipStartTimestamp,
            formatISOZonedDateTimeToLocalDate(
              membership.membershipStartTimestamp,
              membership.timeZoneId
            )
          ) ||
          values.membership.employeeIdentifier !== membership.employeeIdentifier
        ) {
          // edit endpoint is used to change StartTime and employee Id
          const editBody = {
            startDate: formatDateToISODate(values.membership.membershipStartTimestamp),
            employeeIdentifier: values.membership.employeeIdentifier || '',
            timeZoneId: membership.timeZoneId,
          };
          membershipWrapper = await httpPatch(
            `/admin/v1/user/${state.userId}/membership/${values.membership.uuid}`,
            editBody
          );
        }

        if (
          values.membership.membershipCancellationReason !==
            membership.membershipCancellationReason ||
          !isSame(
            values.membership.membershipEndTimestamp,
            formatISOZonedDateTimeToLocalDate(
              membership.membershipEndTimestamp,
              membership.timeZoneId
            )
          )
        ) {
          const endDate = values.membership.membershipEndTimestamp
            ? formatDateToISODate(values.membership.membershipEndTimestamp)
            : null;
          // cancel end point
          const cancelBody = {
            cancellationReason: values.membership.membershipCancellationReason || null,
            endDate: endDate,
          };

          membershipWrapper = await httpPost(
            `/admin/v1/membership/${values.membership.uuid}/cancel`,
            cancelBody
          );
        }

        if (membershipWrapper) {
          dispatch(
            membershipSaveSuccess({
              ...membershipWrapper,
              referrerMembership: referrerMembership,
              referralMembership: referralMembership,
            })
          );
        }

        if (
          moment
            .utc(referralMembership.membershipStartTimestamp)
            .isBefore(
              moment.utc(
                convertGivenLocalDateToUtc(
                  values.membership.membershipStartTimestamp,
                  'DD-MM-YYYY',
                  referralMembership.timeZone
                )
              )
            )
        ) {
          dispatch(openEditChildMembershipModal(referralMembership.userId));
        }
      } catch (error) {
        return {
          [FORM_ERROR]: error,
        };
      }
    };
    return executeWithSpinner(patchMembership());
  };

  const handleMembershipWarningRequestClose = () => {
    setMembershipDatesToConfirm(null);
    setCurrentMembershipValues(null);
  };

  const getTimeZoneId = async (values, user) => {
    let timeZoneId = 'Europe/Berlin';
    if (values?.membershipOfferDto?.sfAccountCountryCode === 'US') {
      const address = formatAddress(user?.addressDto);
      if (!address) {
        return null;
      }

      const urlSearchParams = new URLSearchParams({
        timeZone: true,
        location: address,
      });

      const geocodingUrl = `/v1/geocoding/resolve?${urlSearchParams.toString()}`;
      const userGeoLocation = await httpGet(geocodingUrl);
      timeZoneId = userGeoLocation?.timeZoneId;
      if (!timeZoneId) {
        return null;
      }
    }
    return timeZoneId;
  };

  /** Creates new Membership */
  const submitPostRequest = values => {
    const postMembership = async () => {
      try {
        const timeZoneId = await getTimeZoneId(values, user);
        if (!timeZoneId) {
          setIsInvalidUserAddress(true);
          handleMembershipWarningRequestClose();
          throw new Error('US memberships require a valid address');
        }

        const postBody = {
          startDate: formatDateToISODate(values.membership.membershipStartTimestamp),
          endDate: formatDateToISODate(values.membership.membershipEndTimestamp),
          employeeIdentifier: values.membership.employeeIdentifier || '',
          membershipOfferId: values.membership.membershipOfferId,
          timeZoneId: timeZoneId,
        };

        const membershipWrapper = await httpPost(
          `/admin/v1/user/${state.userId}/membership`,
          postBody
        );
        dispatch(newMembershipSaveSuccess(membershipWrapper));
      } catch (error) {
        console.log('error', error);
        return {
          [FORM_ERROR]: error,
        };
      }
    };
    return executeWithSpinner(postMembership());
  };

  const dateNeedsConfirmation = (currentDate, newDate) => {
    return currentDate !== newDate && isDateTooFarInTime(newDate);
  };

  const membershipDatesAreTooFarInTime = values => {
    const newStartDate = values.membership.membershipStartTimestamp;
    const newEndDate = values.membership.membershipEndTimestamp;
    const datesToConfirm = {};
    if (
      dateNeedsConfirmation(
        formatISOZonedDateTimeToISODate(membership.membershipStartTimestamp, membership.timeZoneId),
        formatDateToISODate(values.membership.membershipStartTimestamp)
      )
    ) {
      datesToConfirm.startDate = newStartDate;
    }

    if (
      dateNeedsConfirmation(
        formatISOZonedDateTimeToISODate(membership.membershipEndTimestamp, membership.timeZoneId),
        formatDateToISODate(values.membership.membershipEndTimestamp)
      )
    ) {
      datesToConfirm.endDate = newEndDate;
    }

    if (Object.keys(datesToConfirm).length > 0) {
      setModalTitle('Warning!');
      setModalBody(
        <span>
          You are setting a date that is quite <b>far in the past/future:</b>
        </span>
      );
      setStartDateLabel(<span>Start date</span>);
      setEndDateLabel(<span>End date</span>);
      setCurrentMembershipValues(values);
      setMembershipDatesToConfirm(datesToConfirm);
      return true;
    }
    return false;
  };

  const membershipDatesAreOutsideEligibilityPeriod = values => {
    const datesToConfirm = {};
    const newStartDate = values.membership.membershipStartTimestamp;
    const newEndDate = values.membership.membershipEndTimestamp;

    let isStartDateEligibilityPeriodInvalid = false;
    let isEndDateEligibilityPeriodInvalid = false;

    if (evaluatedCorporateEmployee) {
      const ineligibilityDurationThresholdInDays = 10;
      const { effectiveFrom, effectiveTo } = evaluatedCorporateEmployee.eligibility;
      const effectiveFromFormatted = effectiveFrom && moment(effectiveFrom).format('DD-MM-YYYY');
      const effectiveToFormatted = effectiveTo && moment(effectiveTo).format('DD-MM-YYYY');

      isStartDateEligibilityPeriodInvalid =
        effectiveFrom &&
        newStartDate &&
        (moment(newStartDate).isBefore(moment(effectiveFromFormatted), 'days') ||
          moment(effectiveToFormatted, 'DD-MM-YYYY').diff(
            moment(newStartDate, 'DD-MM-YYYY'),
            'days'
          ) <= ineligibilityDurationThresholdInDays);

      isEndDateEligibilityPeriodInvalid =
        effectiveTo && newEndDate && moment(newEndDate).isAfter(moment(effectiveTo), 'days');

      if (isStartDateEligibilityPeriodInvalid || isEndDateEligibilityPeriodInvalid) {
        datesToConfirm.startDate = effectiveFrom
          ? moment(effectiveFrom).format('DD-MM-YYYY')
          : 'Not set';
        datesToConfirm.endDate = effectiveTo ? moment(effectiveTo).format('DD-MM-YYYY') : 'Not set';

        const datesFailingLabel = isStartDateEligibilityPeriodInvalid
          ? `Start ${isEndDateEligibilityPeriodInvalid ? 'and End ' : ''}`
          : 'End ';

        const modalBodyStart = `This membership is connected to a HR system and the ${datesFailingLabel} date you have selected `;
        setMembershipDatesToConfirm(datesToConfirm);
        setModalTitle(
          `Warning, ${datesFailingLabel} date will be outside of the eligibility period`
        );
        setStartDateLabel(<span>Eligibility start date</span>);
        setEndDateLabel(<span>Eligibility end date</span>);
        setModalBody(
          <>
            <span>{modalBodyStart}</span> <b>will be outside this persons eligibility period</b>
          </>
        );
      }
    }
    setCurrentMembershipValues(values);
    return isStartDateEligibilityPeriodInvalid || isEndDateEligibilityPeriodInvalid;
  };

  const submitRequest = values => {
    isEditMode() ? submitPatchRequest(values) : submitPostRequest(values);
  };

  const handleSubmitRequest = values => {
    if (
      !membershipDatesAreTooFarInTime(values) &&
      !membershipDatesAreOutsideEligibilityPeriod(values)
    ) {
      submitRequest(values);
    }
  };

  const fetchChargeRequest = simpleMemoize(async () => {
    const chargeRequests = await httpGet(
      `/v1/payment/charge?userId=${membership.userId}&period=${moment().format('YYYY-MM')}`
    );

    return chargeRequests ? chargeRequests.content : [];
  });

  const validate = async values => {
    if (chronology !== 'PAST') {
      const promise = new Promise(resolve => resolve(validateDates(values, state)));
      promise.then(result => {
        result && result.length > 0 ? setWarning(result.join(' ')) : setWarning(null);
      });

      return validateMembership(values, state);
    } else if (
      pastMembershipEndDateStillEditable() &&
      membershipOfferDto.b2cPayment &&
      values.membership.membershipEndTimestamp !==
        (membership.membershipEndTimestamp &&
          moment(membership.membershipEndTimestamp).format('DD-MM-YYYY'))
    ) {
      const errors = { membership: {} };
      const chargeRequests = await fetchChargeRequest();

      if (chargeRequests.length) {
        errors.membership.membershipEndTimestamp = {
          code: VALIDATION_B2C_CHARGE_REQUEST_EXISTS,
        };
      }
      return errors;
    }
  };

  useEffect(() => {
    if (membershipOfferDto && membershipOfferDto.integrationScopeId) {
      httpGet(
        `/v1/corporate-employee?egymAccountId=${state.userId}&integrationScopeId=${membershipOfferDto.integrationScopeId}`
      ).then(corporateEmployee => {
        setEvaluatedCorporateEmployee(corporateEmployee);
      });
    }
  }, [membershipOfferDto, state.userId]);

  return (
    <div className="card mb-3 bg-light">
      <Form
        onSubmit={values => handleSubmitRequest(values)}
        validate={validate}
        keepDirtyOnReinitialize
        initialValues={{
          membership: {
            ...membership,
            membershipStartTimestamp:
              membership && membership.membershipStartTimestamp
                ? formatISOZonedDateTimeToLocalDate(
                    membership.membershipStartTimestamp,
                    membership.timeZoneId
                  )
                : '',
            membershipEndTimestamp:
              membership && membership.membershipEndTimestamp
                ? formatISOZonedDateTimeToLocalDate(
                    membership.membershipEndTimestamp,
                    membership.timeZoneId
                  )
                : '',
            creationTimestamp:
              membership && membership.creationTimestamp
                ? formatISOZonedDateTimeToLocalDate(
                    membership.creationTimestamp,
                    membership.timeZoneId
                  )
                : '',
          },
          membershipOfferDto: {
            ...membershipOfferDto,
            created: formatISOZonedDateTimeToLocalDate(
              membershipOfferDto.created,
              membership.timeZoneId
            ),
          },
          relatedSignupPageNames,
        }}
        render={({ handleSubmit, submitting, pristine, submitError }) => (
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              {isInvalidUserAddress && (
                <div className="px-3">
                  <div className="row alert alert-danger mt-3">
                    US memberships require a valid address. Please open this member in the EGYM user
                    manager and ensure the address is correct and complete.
                  </div>
                </div>
              )}
              {/* //////////     Offer Info     ////////// */}
              <div className="form-row mb-3">
                <h6 className="col">
                  Offer Info{' '}
                  {membershipOfferDto?.sfAccountCountryCode &&
                    getCountryFlag(membershipOfferDto?.sfAccountCountryCode)}
                </h6>
              </div>
              <div className="form-row">
                <div className="col-12 col-lg-4 col-xl-2">
                  <TextFormField
                    controlId="membership.membershipOfferId"
                    controlLabel="Offer ID"
                    disabled
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-2">
                  <div className="form-row">
                    <div className="col-12 col-md-7">
                      <TextFormField
                        controlId="membershipOfferDto.priceAmount"
                        controlLabel="Price"
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-5">
                      <TextFormField
                        controlId="membershipOfferDto.priceCurrency"
                        controlLabel="&nbsp;"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-xl-2">
                  <TextFormField
                    controlId="membershipOfferDto.created"
                    controlLabel="Creation Date"
                    disabled
                  />
                </div>
                <div className="col-12 col-lg-12 col-xl-6">
                  <TextFormField
                    controlId="relatedSignupPageNames"
                    controlLabel="Related Signup Pages"
                    disabled
                  />
                </div>
              </div>
              {/* //////////     Membership Info     ////////// */}
              <div className="form-row my-3">
                <h6 className="col">Membership Info</h6>
              </div>
              <div className="form-row">
                <div className="col-12 col-lg-4 col-xl-2">
                  <TextFormField
                    controlId="membership.membershipStartTimestamp"
                    controlLabel="Start"
                    helpTextAfter={
                      membership.membershipStartTimestamp &&
                      chronology === 'ACTIVE' &&
                      !membershipOfferDto.b2cPayment &&
                      !isMembershipStartTooFarInPast(membership.membershipStartTimestamp) &&
                      moment().isAfter(moment(membership.membershipStartTimestamp)) &&
                      'You are editing a start date that is in the past. Please make sure there are no ' +
                        'check-ins for this member before proceeding. This change will also affect historic company invoices.'
                    }
                    disabled={
                      chronology === 'PAST' ||
                      (chronology === 'ACTIVE' &&
                        ((membership.membershipStartTimestamp && membershipOfferDto.b2cPayment) ||
                          isMembershipStartTooFarInPast(membership.membershipStartTimestamp)))
                    }
                    formatOnBlur
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-2">
                  <TextFormField
                    controlId="membership.membershipEndTimestamp"
                    controlLabel="End"
                    helpTextAfter="Setting the 'End Date' to empty value here will stop the membership cancellation."
                    formatOnBlur
                    disabled={
                      chronology === 'PAST' && !pastMembershipEndDateStillEditable() && isEditMode()
                    }
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-2">
                  <DropdownFormField
                    controlId="membership.membershipCancellationReason"
                    controlLabel="Cancellation Reason"
                    options={membershipCancellationDropdownOptions(membership)}
                    formatOnBlur
                    disabled={!isEditMode()}
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-2">
                  <TextFormField
                    controlId="membership.membershipEndChangeTimestamp"
                    controlLabel="Cancellation Timestamp"
                    disabled
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-2">
                  <TextFormField
                    controlId="membership.creationTimestamp"
                    controlLabel="Signup Date"
                    disabled
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-2">
                  <TextFormField
                    controlId="membership.employeeIdentifier"
                    controlLabel="Personal Identifier"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-12 col-lg-4 col-xl-6">
                  <TextFormField
                    controlId="membership.uuid"
                    controlLabel="Membership UUID"
                    disabled
                  />
                </div>
              </div>
              {warning && <div className="alert alert-warning">{warning}</div>}
              {submitError && <SubmitErrors {...submitError} />}
            </div>
            <div className="card-footer">
              <button
                className="btn btn-secondary mr-2"
                type="submit"
                disabled={submitting || pristine}
              >
                Save
              </button>
              <button
                className="btn btn-primary"
                onClick={() =>
                  isEditMode()
                    ? dispatch(cancelEditingMembership(membership.uuid))
                    : dispatch(cancelNewMembership())
                }
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      />
      {membershipDatesToConfirm && (
        <>
          <DateWarningModal
            modalTitle={modalTitle}
            onConfirm={() => submitRequest(currentMembershipValues)}
            onRequestClose={handleMembershipWarningRequestClose}
            startDate={membershipDatesToConfirm.startDate}
            endDate={membershipDatesToConfirm.endDate}
            startDateLabel={startDateLabel}
            endDateLabel={endDateLabel}
            modalBody={modalBody}
          />
        </>
      )}
    </div>
  );
};

export default EditMembership;
