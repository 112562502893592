import React, { useEffect, useState, useCallback } from 'react';
import { httpGet } from '../../../service/http';
import AcceptOfferIdModal from './AcceptOfferIdModal';
import CompanyDifferenceModal from './CompanyDifferenceModal';
import MoveMembershipConfirmationModal from './MoveMembershipConfirmationModal';
import OfferTypeDifferenceModal from './OfferTypeDifferenceModal';
import { getActiveOffer } from '../../../utils/offer/offerUtil';
import CountryDifferenceModal from './CountryDifferenceModal';

type Props = {
  onCancel: Function,
  sfAccountCanonicalId: string,
  companyName: string,
  companyAddress: String,
};

const Steps = {
  ACCEPT_OFFER_ID: 'ACCEPT_OFFER_ID',
  COMPANY_DIFFERENCE: 'COMPANY_DIFFERENCE',
  OFFER_TYPE_DIFFERENCE: 'OFFER_TYPE_DIFFERENCE',
  CONFIRMATION: 'CONFIRMATION',
  DIFFERENT_COUNTRY_ERROR: 'DIFFERENT_COUNTRY_ERROR',
};

const MoveMembershipsToNewOffer = ({
  onCancel,
  sfAccountCanonicalId,
  companyName,
  companyAddress,
}: Props) => {
  const [offerId, setOfferId] = useState('');
  const [isPlusOneOffer, setPlusOneOffer] = useState(false);
  const [showB2CWarning, setShowB2CWarning] = useState(false);
  const [mismatchOffers, setMismatchOffers] = useState([]);

  const [fetchedNewOffer, setFetchedNewOffer] = useState(undefined);
  const [pastOffers, setPastOffers] = useState([]);
  const [activeOffer, setActiveOffer] = useState();

  const [currentStep, setCurrentStep] = useState(Steps.ACCEPT_OFFER_ID);
  const [stepsHistoryStack, setStepsHistoryStack] = useState([Steps.ACCEPT_OFFER_ID]);

  const pushStepInHistoryStack = step => {
    setStepsHistoryStack(prevStack => [...prevStack, step]);
  };

  const getMismatchedOffers = (newOfferType, pastOffers) => {
    return pastOffers?.filter(pastOffer => pastOffer.type !== newOfferType);
  };
  const retrieveOffer = async offerId => {
    return fetchedNewOffer && Number(fetchedNewOffer.id) === Number(offerId)
      ? fetchedNewOffer
      : await httpGet(`/v1/membership-offer/${offerId}`);
  };

  const getPreviousOffers = useCallback(async sfAccountCanonicalId => {
    const pastOffers = await httpGet(`/admin/v1/company/${sfAccountCanonicalId}/offer`);
    setPlusOneOffer(pastOffers.some(offer => offer.type === 'PLUS_ONE'));
    setPastOffers(pastOffers);
    setActiveOffer(getActiveOffer(pastOffers));
  }, []);

  useEffect(() => {
    if (!sfAccountCanonicalId) {
      return;
    }
    getPreviousOffers(sfAccountCanonicalId);
  }, [sfAccountCanonicalId, getPreviousOffers]);

  const tryOpenDifferentCountryErrorModal = retrievedOffer => {
    if (activeOffer?.sfAccountCountryCode === retrievedOffer?.sfAccountCountryCode) {
      return false;
    }
    pushStepInHistoryStack(Steps.ACCEPT_OFFER_ID);
    setCurrentStep(Steps.DIFFERENT_COUNTRY_ERROR);
    return true;
  };

  const tryOpenCompanyDifferenceModal = async retrievedOffer => {
    if (stepsHistoryStack.includes(Steps.COMPANY_DIFFERENCE)) {
      return false;
    }

    if (sfAccountCanonicalId === retrievedOffer?.sfAccountCanonicalId) {
      return false;
    }
    pushStepInHistoryStack(Steps.COMPANY_DIFFERENCE);
    setCurrentStep(Steps.COMPANY_DIFFERENCE);
    return true;
  };

  const tryOpenOfferTypeDifferenceModal = async retrievedOffer => {
    if (stepsHistoryStack.includes(Steps.OFFER_TYPE_DIFFERENCE)) {
      return false;
    }

    const offersWithDifferentTypes = getMismatchedOffers(retrievedOffer.type, pastOffers);
    setMismatchOffers(offersWithDifferentTypes);
    if (!offersWithDifferentTypes.length) {
      return false;
    }

    pushStepInHistoryStack(Steps.OFFER_TYPE_DIFFERENCE);
    setCurrentStep(Steps.OFFER_TYPE_DIFFERENCE);
    return true;
  };

  const tryOpenConfirmationModal = async retrievedOffer => {
    if (pastOffers) {
      const nonB2CPastOffers = pastOffers?.filter(pastOffer => pastOffer.b2cPayment === false);
      setShowB2CWarning(retrievedOffer?.b2cPayment && !!nonB2CPastOffers.length);
    }
    pushStepInHistoryStack(Steps.CONFIRMATION);
    setCurrentStep(Steps.CONFIRMATION);
    return true;
  };

  const loadNextModal = async () => {
    const newOffer = await retrieveOffer(offerId);
    setFetchedNewOffer(newOffer);
    if (newOffer.type === 'PLUS_ONE') {
      setPlusOneOffer(true);
      return;
    }
    tryOpenDifferentCountryErrorModal(newOffer) ||
      (await tryOpenCompanyDifferenceModal(newOffer)) ||
      (await tryOpenOfferTypeDifferenceModal(newOffer)) ||
      (await tryOpenConfirmationModal(newOffer));
  };

  const goBackToPreviousModal = () => {
    if (stepsHistoryStack.length <= 1) {
      return;
    }

    const stackValues = [...stepsHistoryStack];
    stackValues.pop();
    setCurrentStep(stackValues[stackValues.length - 1]);
    setStepsHistoryStack(stackValues);
  };

  return (
    <>
      {fetchedNewOffer && currentStep === Steps.DIFFERENT_COUNTRY_ERROR && (
        <CountryDifferenceModal
          onCancel={goBackToPreviousModal}
          oldSfAccountCountryCode={activeOffer?.sfAccountCountryCode}
          newSfAccountCountryCode={fetchedNewOffer.sfAccountCountryCode}
        />
      )}
      {currentStep === Steps.ACCEPT_OFFER_ID && (
        <AcceptOfferIdModal
          onOfferIdChange={e => setOfferId(e.target.value)}
          offerId={offerId}
          loadNextPage={loadNextModal}
          onCancel={onCancel}
          isPlusOneOffer={isPlusOneOffer}
        />
      )}
      {fetchedNewOffer && currentStep === Steps.COMPANY_DIFFERENCE && (
        <CompanyDifferenceModal
          loadNextPage={loadNextModal}
          onCancel={goBackToPreviousModal}
          sfAccountCanonicalId={sfAccountCanonicalId}
          companyName={companyName}
          newSfAccountCanonicalId={fetchedNewOffer.sfAccountCanonicalId}
        />
      )}
      {fetchedNewOffer && currentStep === Steps.OFFER_TYPE_DIFFERENCE && (
        <OfferTypeDifferenceModal
          loadNextPage={loadNextModal}
          onCancel={goBackToPreviousModal}
          newOffer={fetchedNewOffer}
          oldMismatchedOffers={mismatchOffers}
        />
      )}

      {currentStep === Steps.CONFIRMATION && (
        <MoveMembershipConfirmationModal
          loadNextPage={loadNextModal}
          onCancel={onCancel}
          sfAccountCanonicalId={sfAccountCanonicalId}
          companyName={companyName}
          companyAddress={companyAddress}
          newOffer={fetchedNewOffer}
          showB2CWarning={showB2CWarning}
        />
      )}
    </>
  );
};

export default MoveMembershipsToNewOffer;
