import React from 'react';
import Modal from '../../common/modal/Modal';

type Props = {
  onOfferIdChange: Function,
  offerId: number,
  loadNextPage: Function,
  onCancel: Function,
};

const AcceptOfferIdModal = ({
  onOfferIdChange,
  offerId,
  loadNextPage,
  onCancel,
  isPlusOneOffer,
}: Props) => {
  return (
    <Modal isOpen={true} onRequestClose={onCancel} modalTitle="Move all members to a new offer">
      <div className="form-group">
        <label htmlFor="offerId" className={'text-primary'}>
          Enter the <strong>new offer id</strong>, where you want to move members to.
        </label>
        <input
          type="number"
          id="offerId"
          value={offerId}
          className="form-control"
          onChange={onOfferIdChange}
        />
      </div>

      {isPlusOneOffer && (
        <div className="alert alert-danger">
          {'Memberships cannot be moved either from or to offers with type PLUS ONE.'}
        </div>
      )}

      <div className="form-group form-row">
        <div className="col-sm-9" />
        <button
          className="btn btn-secondary col-sm-3"
          type="button"
          disabled={!offerId || isPlusOneOffer}
          onClick={loadNextPage}
        >
          Next
        </button>
      </div>
    </Modal>
  );
};
export default AcceptOfferIdModal;
