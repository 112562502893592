// @flow
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import OfferManager from '../offer-details/OfferManager';
import EligitablityRules from '../eligibility-rules/EligibilityRules';

const OfferManagerSubRoutes = () => (
  <Routes>
    <Route path="details" element={<OfferManager />} />
    <Route path="eligibility-rules" element={<EligitablityRules />} />
    <Route index element={<Navigate to="details" />} />
  </Routes>
);

export default OfferManagerSubRoutes;
